@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;700&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  font-size: 62.5%;
}

body {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100vw;
  height: 100vh;

  background: url(../public/assets/background.png) no-repeat;
  background-size: cover;
  font-family: 'Poppins';
}

.image_logo {
  position: absolute;
  left: 0;
  top: 0;
  padding: 3rem;
}

main {
  display: flex;
  align-items: center;
}

section {
  padding: 2rem;
  margin-top: 6rem;
}

h1 {
  color: #FFFFFF;
  text-align: end;
  font-size: 5.5rem;
  line-height: 5.2rem;
}

h3 {
  margin-right: -4.3rem;
  color: #E94560;

  text-align: end;
  font-size: 4.2rem;
  line-height: 5rem;
  text-shadow: 0px 0px 20px #E94560;
}

p {
  color: #FFFFFF;
  font-size: 1.8rem;
  font-weight: 400;
  letter-spacing: 0rem;
}

p span {
  color: #E94560;
  text-shadow: 0px 0px 20px #E94560;
}

.image_phone {
  width: 26rem;
  margin-left: 2.1rem;
}

.div_phone_responsive {
  display: none;
}

.div_text {
  max-width: 41.5rem;
  width: 100%;
  margin-top: 7rem;
}

.div_icons {
  display: flex;
  gap: 1.2rem;

  margin-top: 2.3rem;
  color: #ffff;
}

.div_icons a:hover {
  border-radius: 6rem;
  transition-duration: 0.4s;
  transform: scale(1.1);
}