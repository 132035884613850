@media (max-width: 780px) {
  html {
    font-size: 45%;
  }

  .image_logo {
    width: 140px;
  }

  .image_phone {
    width: 190px;
  }

  .div_icons img {
    gap: 0;
    width: 5rem;
  }

  .div_phone_responsive {
    display: none;
  }
}

@media (max-width: 570px) {
  html {
    font-size: 35%;
  }

  .image_logo {
    width: 130px;
  }

  .image_phone {
    width: 150px;
  }

  .div_phone_responsive {
    display: none;
  }
}

@media (max-width: 460px) {
  html {
    font-size: 35%;
  }

  .image_logo {
    width: 130px;
  }

  main {
    display: block;
    align-items: center;
  }

  section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 0;
  }

  .div_text {
    max-width: 40rem;
    margin-top: 2rem;
    text-align: center;
  }

  .div_title {
    margin-bottom: 2rem;
  }

  .div_title,
  .div_text {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  h3 {
    margin: 0;
    text-align: center;
  }

  .div_phone {
    display: none;
  }

  .div_phone_responsive {
    display: block;
  }

  .image_phone {
    width: 150px;
  }

}

@media (max-width: 230px) {
  html {
    font-size: 25%;
  }

  .image_logo {
    width: 90px;
  }

  section {
    display: flex;
    flex-direction: column;
  }

  .div_phone {
    display: none;
  }

  .div_phone_responsive {
    display: block;
  }

  .image_phone {
    top: 255px;
    width: 80px;
  }
}